import React from 'react';
import { AdminHeader } from '../components/Admin';
import { AdminRoutes } from '../routes';
import styles from '../components/Admin/admin.module.css';

const AdminPage = () => {
  return (
    <div className={styles.adminBack}>
      <AdminHeader />
      <AdminRoutes />
    </div>
  );
};

export default AdminPage;
