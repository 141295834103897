import React, { Component } from 'react';
import { Table } from 'react-materialize';
import styles from '../admin.module.css';
import { connect } from 'react-redux';
import { getUsers, addAdmin } from '../actions';
import PropTypes from 'prop-types';
import { Button } from 'react-materialize';

class UsersList extends Component {
  componentDidMount() {
    this.props.getUsers();
  }

  render() {
    return (
      this.props.users && (
        <Table className={styles.tblStyle}>
          <thead>
            <tr>
              <th data-field="emailAddress">Email</th>
              <th data-field="Admin">Admin</th>
              <th data-field="setAdmin" />
            </tr>
          </thead>
          <tbody>
            {this.props.users.map((user, index) => (
              <tr key={index}>
                <td>{user.emailAddress}</td>
                <td>
                  {user.admin ? (
                    'Yes'
                  ) : (
                    <span>
                      No<Button
                        className={styles.addAdminButtonstyle}
                        onClick={() => this.props.addAdmin(user)}
                      >
                        set
                      </Button>
                    </span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )
    );
  }
}

UsersList.propTypes = {
  getUsers: PropTypes.func,
  addAdmin: PropTypes.func,
  users: PropTypes.array
};

const mapStateToProps = ({ admin }) => ({
  users: admin.users
});

export default connect(
  mapStateToProps,
  { getUsers, addAdmin }
)(UsersList);
