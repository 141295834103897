import React from 'react';
import PropTypes from 'prop-types';
import styles from './gallery.module.css';
import classNames from 'classnames';
export const ImageCaption = ({ imgName, imgDescription }) => [
  <span key={1} className={styles.imgCpation}>
    {imgName}
  </span>,
  <span key={2} className={classNames(styles.imgCpation, styles.imgDescription)}>
    {imgDescription}
  </span>
];

ImageCaption.propTypes = {
  imgName: PropTypes.string,
  imgDescription: PropTypes.string
};

export default ImageCaption;
