import React, { Component } from 'react';
import styles from '../header.module.css';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Navbar } from 'react-materialize';
import PropTypes from 'prop-types';
import DivWrapper from '../../Common/Div-Wrapper';
import mixpanel from 'mixpanel-browser';
import { MIXPANEL_TOKEN } from '../../../constants/config';
import { trackMixPanel } from '../../common-actions';
import { connect } from 'react-redux';
import styled from 'styled-components';

const StyledNavbar = styled(Navbar)`
  font-family: 'Poiret One';
  box-shadow: 0 0 0 0 !important;
  background-color: ${props => props.theme.colors.sitePrimary}
  padding-left: env(safe-area-inset-left) !important;
  padding-right: env(safe-area-inset-right) !important;
`;

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clicked:
        this.props.pathName !== '/'
          ? this.props.pathName.substring(
              this.props.pathName.indexOf('/') + 1,
              this.props.pathName.length
            )
          : 'home'
    };
  }

  componentDidMount() {
    mixpanel.init(MIXPANEL_TOKEN);
  }

  changeClick(val) {
    this.setState({ clicked: val });
    this.props.trackMixPanel({
      actionName: 'Nabar_Click',
      page: val
    });
  }

  getClassName(val) {
    const classNamesRegular = styles.headerText;
    const classNamesActive = classNames(styles.headerText, 'active');

    if (this.state.clicked === val) return classNamesActive;

    return classNamesRegular;
  }

  render() {
    return (
      <DivWrapper element="header">
        <StyledNavbar
          brand="Tomer Amir"
          right
          className="navGlobal"
          // className={classNames(styles.navDesign, 'navGlobal')}
          options={{ closeOnClick: true }}
        >
          <li className={styles.WrapperText}>
            <Link
              className={this.getClassName('home')}
              to="/"
              onClick={() => this.changeClick('home')}
            >
              Home
            </Link>
          </li>
          <li className={styles.WrapperText}>
            <Link
              to="/recentWork"
              onClick={() => this.changeClick('recentWork')}
              className={this.getClassName('recentWork')}
            >
              recent work
            </Link>
          </li>
          <li className={styles.WrapperText}>
            <Link
              to="/2015_2018"
              onClick={() => this.changeClick('2015_2018')}
              className={this.getClassName('2015_2018')}
            >
              2015-2018
            </Link>
          </li>
          <li className={styles.WrapperText}>
            <Link
              to="/earlierWork"
              onClick={() => this.changeClick('earlierWork')}
              className={this.getClassName('earlierWork')}
            >
              earlier work
            </Link>
          </li>
          <li className={styles.WrapperText}>
            <Link
              to="/cv"
              onClick={() => this.changeClick('cv')}
              className={this.getClassName('cv')}
            >
              cv
            </Link>
          </li>

          <li className={styles.WrapperText}>
            <Link
              className={this.getClassName('contact')}
              to="/contact"
              onClick={() => this.changeClick('contact')}
            >
              contact
            </Link>
          </li>
        </StyledNavbar>
      </DivWrapper>
    );
  }
}

Header.propTypes = {
  pathName: PropTypes.string,
  trackMixPanel: PropTypes.func
};

export default connect(
  null,
  {
    trackMixPanel
  }
)(Header);
