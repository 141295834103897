import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button, Row, Col } from 'react-materialize';
import DropzoneInput from '../../Common/Drop-Zone/drop-zone-input';
import { required, fileSize } from '../../../utils/validate';
import { connect } from 'react-redux';
import InputField from '../../Common/Input/input-field';
import styles from '../admin.module.css';
import { uploadImages } from '../actions';
import PropTypes from 'prop-types';
import { Loader } from '../../../components/Common/Loader';
import { imagesCategory } from '../../../constants/admin.js';

class UploadForm extends Component {
  onSubmit = values => {
    let formData = new FormData();
    formData.append('image', values.files[0]);
    formData.append('imageName', values.imageName);
    formData.append('imageDescription', values.imageDescription);
    formData.append('imageCategory', values.imageCategory);
    this.props.uploadImages(formData);
  };

  render() {
    return (
      <Loader id="uploadImage">
        <form className={styles.formDesign} onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <Field
            name="imageName"
            type="text"
            component={InputField}
            label="Image Name"
            labelClass={styles.labelStyle}
            inputClass={styles.textStyle}
            validate={required}
            fieldWwrapName={styles.uploadField}
          />
          <Field
            name="imageDescription"
            type="text"
            component={InputField}
            label="Image description"
            labelClass={styles.labelStyle}
            inputClass={styles.textStyle}
            validate={required}
            fieldWwrapName={styles.uploadField}
          />
          <Field
            name="imageCategory"
            type="select"
            component={InputField}
            label="Image Category"
            labelClass={styles.labelStyle}
            inputClass={styles.textStyle}
            selectOptions={imagesCategory}
            fieldWwrapName={styles.uploadField}
          />
          <Field
            name="files"
            component={DropzoneInput}
            label="Image"
            labelClass={styles.labelStyle}
            validate={[required, fileSize]}
            fieldWwrapName={styles.uploadField}
          />
          <Row>
            <Col className={styles.test} xl={12} l={12} m={12} s={12}>
              <Button type="submit">Upload Image</Button>

              <Button type="button" className={styles.clearButton} onClick={this.props.reset}>
                Clear Values
              </Button>
            </Col>
          </Row>
        </form>
      </Loader>
    );
  }
}

UploadForm.propTypes = {
  uploadImages: PropTypes.func,
  handleSubmit: PropTypes.func,
  reset: PropTypes.func
};

export default connect(
  null,
  { uploadImages }
)(
  reduxForm({
    form: 'upload',
    initialValues: {
      imageCategory: 'Earlier'
    },
    touchOnChange: true
  })(UploadForm)
);
