import {
  API_REQUEST,
  FETCH_USER,
  FILES_UPDATE,
  FILES_UPLOAD,
  AUTH_GOOGLE,
  ADMIN_UPDATE_IMAGE,
  ADMIN_CHANGE_IMG_EDIT_WINDOW_STATUS,
  ADMIN_DELETE_IMAGE,
  ADMIN_GET_USERS,
  ADMIN_ADD_ADMIN
} from '../../../constants/requests';

export function fetchUser() {
  return {
    type: API_REQUEST,
    payload: {
      url: '/auth/current_user',
      method: 'get',
      nextActionType: FETCH_USER
    }
  };
}

export function uploadImages(data) {
  return {
    type: API_REQUEST,
    payload: {
      url: '/upload/uploadimg',
      method: 'post',
      data,
      nextActionType: FILES_UPLOAD
    },
    meta: {
      loaderId: 'uploadImage',
      toast: {
        messageSuccess: 'Image upload success',
        messageFailed: 'Image  upload failed'
      }
    }
  };
}

export function authGoogle() {
  return {
    type: API_REQUEST,
    payload: {
      url: '/auth/google',
      method: 'get',
      nextActionType: AUTH_GOOGLE
    }
  };
}

export function updateImages(accepted, rejected) {
  return {
    type: FILES_UPDATE,
    payload: {
      accepted: accepted,
      rejected: rejected
    }
  };
}

export function updateImage(data) {
  return {
    type: API_REQUEST,
    payload: {
      url: '/images/updateImage',
      method: 'post',
      data,
      nextActionType: ADMIN_UPDATE_IMAGE
    },
    meta: {
      loaderId: 'updateImage',
      imageCategory: data.imageCategory,
      toast: {
        messageSuccess: 'Image  update successfully',
        messageFailed: 'Image update failed'
      }
    }
  };
}

export function changeImgEditWindowStatus() {
  return {
    type: ADMIN_CHANGE_IMG_EDIT_WINDOW_STATUS
  };
}

export function deleteImage(data) {
  return {
    type: API_REQUEST,
    payload: {
      url: '/images/deleteImage',
      method: 'delete',
      data,
      nextActionType: ADMIN_DELETE_IMAGE
    },
    meta: {
      loaderId: 'deleteImage',
      imageCategory: data.imageCategory,
      toast: {
        messageSuccess: 'Image delete successfully',
        messageFailed: 'Image delete update failed'
      }
    }
  };
}

export function getUsers() {
  return {
    type: API_REQUEST,
    payload: {
      url: '/users/getUsers',
      method: 'get',
      nextActionType: ADMIN_GET_USERS
    },
    meta: {
      loaderId: 'getUsers'
    }
  };
}

export function addAdmin(data) {
  return {
    type: API_REQUEST,
    payload: {
      url: '/admins/addAdmin',
      method: 'post',
      nextActionType: ADMIN_ADD_ADMIN,
      data
    },
    meta: {
      toast: {
        messageSuccess: 'User set to admin success',
        messageFailed: 'User set to admin failed'
      }
    }
  };
}
