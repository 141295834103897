import { GALLERY_CHANGE_IMAGE_STATUS, APP_TRACK_MIX_PANEL } from '../constants/requests';

export function trackMixPanel(mixPanelObjects) {
  return {
    type: APP_TRACK_MIX_PANEL,
    payload: {
      mixPanelObjects: Array.isArray(mixPanelObjects) ? mixPanelObjects : [mixPanelObjects]
    }
  };
}

export function changeImageStatus(imageId) {
  return {
    type: GALLERY_CHANGE_IMAGE_STATUS,
    payload: {
      id: imageId
    }
  };
}
