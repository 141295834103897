import { API_REQUEST, FETCH_EARLIER_IMAGES } from '../../../constants/requests';

export function fetchEarlierImages() {
  return {
    type: API_REQUEST,
    payload: {
      url: '/images/getEarlierImages',
      method: 'get',
      nextActionType: FETCH_EARLIER_IMAGES
    },
    meta: {
      loaderId: 'fetchEarlierImages'
    }
  };
}
