import { FILES_UPDATE } from '../constants/requests';

const initinialState = {
  accepted: [],
  rejected: []
};
export default (state = initinialState, action) => {
  switch (action.type) {
    case FILES_UPDATE: {
      return {
        ...state,
        accepted: action.payload.accepted,
        rejected: action.payload.rejected
      };
    }
    default:
      return state;
  }
};
