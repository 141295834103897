import {
  API_REQUEST,
  ADMIN_FETCH_RECENT_IMAGES,
  ADMIN_UPDATE_RECENT_IMAGES_ORDER,
  ADMIN_SORT_RECENT_IMAGES
} from '../../../constants/requests';

export function fetchRecentImages() {
  return {
    type: API_REQUEST,
    payload: {
      url: '/images/getRecentImages',
      method: 'get',
      nextActionType: ADMIN_FETCH_RECENT_IMAGES
    },
    meta: {
      loaderId: 'adminfetchRecentImages'
    }
  };
}

export function sortRecentImages(data) {
  return {
    type: ADMIN_SORT_RECENT_IMAGES,
    payload: {
      data
    }
  };
}

export function updateRecentImages(data) {
  return {
    type: API_REQUEST,
    payload: {
      url: '/images/updateRecentImagesOrder',
      method: 'post',
      data,
      nextActionType: ADMIN_UPDATE_RECENT_IMAGES_ORDER
    },
    meta: {
      loaderId: 'updateRecentImages',
      toast: {
        messageSuccess: 'Images order update successfully',
        messageFailed: 'Images order update failed'
      }
    }
  };
}
