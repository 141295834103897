import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import Lightbox from 'react-images';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import styles from './gallery.module.css';
import {
  calculateAspectRatioFit,
  getGallerySizes,
  sortImagesArrayByOrder
} from '../../../utils/images-util';
import classNames from 'classnames';
import { Row, Col } from 'react-materialize';
import ImageCaption from './img-caption';
import { trackMixPanel } from '../../common-actions';
import DivWrapper from '../Div-Wrapper';
import { connect } from 'react-redux';
import withScreenDimensions from '../../../hoc/with-screen-dimensions';
import { screenWidth as screenWidthConstants } from '../../../constants/responsive';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

class Gallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photoIndex: 0,
      isOpen: false,
      // currentImage: 0,
      // lightboxIsOpen: false,
      containerWidth: null
    };
    this.closeLightbox = this.closeLightbox.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.gotoPrevious = this.gotoPrevious.bind(this);
    this.imagesConatiner = React.createRef();
  }
  componentDidMount() {
    if (this.imagesConatiner.current && this.imagesConatiner.current.clientWidth) {
      this.setState({ ...this.state, containerWidth: this.imagesConatiner.current.clientWidth });
    }
  }
  openLightbox(event, obj) {
    this.setState({
      ...this.state,
      currentImage: obj.index,
      lightboxIsOpen: true
    });
  }
  closeLightbox() {
    this.setState({
      ...this.state,
      currentImage: 0,
      lightboxIsOpen: false
    });
  }
  gotoPrevious() {
    this.setState({
      ...this.state,
      currentImage: this.state.currentImage - 1
    });
  }
  gotoNext() {
    this.setState({
      ...this.state,
      currentImage: this.state.currentImage + 1
    });
  }

  preventScroll(e) {
    e.preventDefault();
  }

  imgOnClick(index) {
    if (this.props.category) {
      this.props.trackMixPanel({
        actionName: 'Gallery_image_click',
        category: this.props.category
      });
    }

    window.addEventListener('touchmove', this.preventScroll);
    disableBodyScroll(this.imagesConatiner.current);
    // document.body.style.overflow = 'hidden';
    this.setState({
      ...this.state,
      isOpen: true,
      photoIndex: index
      // lightboxIsOpen: true,
      //currentImage: index
    });
  }

  render() {
    const windowWidth = this.props.screenWidth;
    const gallerySizes = getGallerySizes(windowWidth);
    const maxWidthForImages = windowWidth - gallerySizes.boxMarginSide * 2;
    const {
      images,
      maxImageHeight,
      showGalleryByImagesPerRow,
      showGalleryByMaxImageHeight
    } = this.props;

    let imgMaxWidth = null;
    if (showGalleryByImagesPerRow) {
      imgMaxWidth =
        (windowWidth -
          gallerySizes.boxMarginSide * 2 -
          gallerySizes.numOfImagePerRow * gallerySizes.spaceBetweenImages -
          gallerySizes.spaceBetweenImages) /
        gallerySizes.numOfImagePerRow;
    }

    const imagesSet = sortImagesArrayByOrder(images, gallerySizes.sortOrder).map((img, index) => ({
      src: img.url,
      thumbnail: img.url,
      thumbnailWidth: calculateAspectRatioFit(
        img.width,
        img.height,
        imgMaxWidth ? imgMaxWidth : img.width,
        this.props.maxImageHeight ? maxImageHeight : 300
      ).width,
      thumbnailHeight: calculateAspectRatioFit(
        img.width,
        img.height,
        imgMaxWidth ? imgMaxWidth : img.width,
        this.props.maxImageHeight ? maxImageHeight : 300
      ).height,
      caption: <ImageCaption key={index} imgName={img.name} imgDescription={img.description} />,
      index,
      key: index
    }));

    let imagesRows = [];
    if (showGalleryByImagesPerRow) {
      for (let i = 0; i < imagesSet.length; i += gallerySizes.numOfImagePerRow) {
        if (i + gallerySizes.numOfImagePerRow < imagesSet.length) {
          imagesRows = [
            ...imagesRows,
            { images: imagesSet.slice(i, i + gallerySizes.numOfImagePerRow) }
          ];
        } else {
          imagesRows = [...imagesRows, { images: imagesSet.slice(i) }];
        }
      }
    } else if (showGalleryByMaxImageHeight) {
      let totalImagesWidth = 0;
      let curIndex = 0;
      imagesRows = imagesSet.reduce((total, cur, index) => {
        if (
          totalImagesWidth + cur.thumbnailWidth + gallerySizes.spaceBetweenImages * 2 >
            maxWidthForImages ||
          index === imagesSet.length - 1
        ) {
          const curImgObj = {
            start: curIndex,
            last: index - 1,
            totalImagesWidth,
            images: imagesSet.slice(curIndex, index)
          };
          if (
            index === imagesSet.length - 1 &&
            totalImagesWidth + cur.thumbnailWidth + gallerySizes.spaceBetweenImages * 2 <
              maxWidthForImages
          ) {
            return [
              ...total,
              {
                ...curImgObj,
                last: index,
                totalImagesWidte:
                  totalImagesWidth + cur.thumbnailWidth + gallerySizes.spaceBetweenImages * 2,
                images: imagesSet.slice(curIndex)
              }
            ];
          } else if (
            index === imagesSet.length - 1 &&
            totalImagesWidth + cur.thumbnailWidth + gallerySizes.spaceBetweenImages * 2 >
              maxWidthForImages
          ) {
            const lastImgObj = {
              start: index,
              last: index,
              totalImagesWidte: cur.thumbnailWidth + gallerySizes.spaceBetweenImages * 2,
              images: imagesSet.slice(index)
            };
            return [...total, curImgObj, lastImgObj];
          }
          totalImagesWidth = cur.thumbnailWidth + gallerySizes.spaceBetweenImages;
          curIndex = index;
          return [...total, curImgObj];
        }
        totalImagesWidth += cur.thumbnailWidth + gallerySizes.spaceBetweenImages;
        return total;
      }, []);
    }

    const showasRow = showGalleryByImagesPerRow || showGalleryByMaxImageHeight;
    let imagesCols = {};
    if (!showasRow) {
      imagesCols = imagesSet.reduce((total, cur, index) => {
        return total[index % gallerySizes.numOfCols]
          ? {
              ...total,
              [index % gallerySizes.numOfCols]: [...total[index % gallerySizes.numOfCols], cur]
            }
          : { ...total, [index % gallerySizes.numOfCols]: [cur] };
      }, {});
    }
    const imgPrecentSize =
      (100 / gallerySizes.numOfCols) * ((100 - gallerySizes.imageMaxWidth) / 100);
    const marginTopImages =
      this.state.containerWidth && this.state.containerWidth * (imgPrecentSize / 100) + 10;
    return (
      <DivWrapper minHeight element="imagesContainer">
        <div
          ref={this.imagesConatiner}
          style={{
            marginRight: `${gallerySizes.boxMarginSide}px`,
            marginLeft: `${gallerySizes.boxMarginSide}px`,
            overflow: this.state.isOpen && 'hidden',
            position: this.state.isOpen && 'relative',
            height: this.state.isOpen && '100%'
          }}
          className={classNames(styles.imgWrap, 'mainGallery')}
        >
          {/* <Lightbox
            showImageCount={false}
            images={imagesSet}
            onClose={this.closeLightbox}
            onClickPrev={this.gotoPrevious}
            onClickNext={this.gotoNext}
            currentImage={this.state.currentImage}
            isOpen={this.state.lightboxIsOpen}
          /> */}
          {this.state.isOpen && (
            <Lightbox
              reactModalProps={{ shouldReturnFocusAfterClose: false }}
              mainSrc={imagesSet.map(img => img.src)[this.state.photoIndex]}
              nextSrc={
                imagesSet.length > this.state.photoIndex + 1
                  ? imagesSet[this.state.photoIndex + 1].src
                  : null
              }
              prevSrc={this.state.photoIndex > 0 ? imagesSet[this.state.photoIndex - 1].src : null}
              imageCaption={imagesSet[this.state.photoIndex].caption}
              enableZoom={false}
              imagePadding={this.props.screenWidth > screenWidthConstants.xs ? 60 : 50}
              onCloseRequest={() => {
                this.setState({ isOpen: false });
                // document.body.style.overflow = 'auto';
                enableBodyScroll(this.imagesConatiner.current);
                window.removeEventListener('touchmove', this.preventScroll);
              }}
              onMovePrevRequest={() =>
                this.setState({
                  ...this.state,
                  photoIndex: this.state.photoIndex - 1
                })
              }
              clickOutsideToClose={false}
              onMoveNextRequest={() => {
                this.setState({
                  ...this.state,
                  photoIndex: this.state.photoIndex + 1
                });
              }}
              animationDisabled={true}
            />
          )}
          {showasRow &&
            imagesRows.map(element => (
              <Row>
                {element.images.map(img => (
                  <img
                    height={img.thumbnailHeight}
                    width={img.thumbnailWidth}
                    src={img.src}
                    alt={img.caption}
                    className={styles.imgStyle}
                    style={{
                      'margin-left': `${gallerySizes.spaceBetweenImages}px`,
                      'margin-top': '50px'
                    }}
                    onClick={() => this.imgOnClick(img.index, img.category)}
                  />
                ))}
              </Row>
            ))}
          {!showasRow && (
            <Row>
              {Object.keys(imagesCols).map((key, index) => (
                <Col
                  key={index}
                  style={{
                    width: `${100 / gallerySizes.numOfCols}%`
                  }}
                >
                  {imagesCols[key].map((img, i) => (
                    <Row
                      key={i}
                      style={{
                        marginTop: `${marginTopImages}px`
                      }}
                    >
                      <img
                        src={img.src}
                        alt={img.caption}
                        className={styles.imgStyle}
                        style={
                          i + 1 === imagesCols[key].length
                            ? {
                                maxWidth:
                                  windowWidth > 1600 ? '55%' : `${gallerySizes.imageMaxWidth}%`,
                                height: 'auto',
                                marginBottom: `${marginTopImages}px`
                              }
                            : {
                                maxWidth:
                                  windowWidth > 1600 ? '55%' : `${gallerySizes.imageMaxWidth}%`,
                                height: 'auto'
                              }
                        }
                        key={i}
                        onClick={() => this.imgOnClick(img.index)}
                      />
                    </Row>
                  ))}
                </Col>
              ))}
            </Row>
          )}
        </div>
      </DivWrapper>
    );
  }
}

Gallery.propTypes = {
  images: PropTypes.array,
  maxImageHeight: PropTypes.number,
  // showGalleryByCols: PropTypes.bool,
  showGalleryByImagesPerRow: PropTypes.bool,
  showGalleryByMaxImageHeight: PropTypes.bool,
  trackMixPanel: PropTypes.func,
  category: PropTypes.string,
  screenWidth: PropTypes.number
  // imagesPerRow: PropTypes.object
};

Gallery.defaultProps = {
  showGalleryByCols: true,
  showGalleryByImagesPerRow: false,
  showGalleryByMaxImageHeight: false
};

export default connect(
  null,
  {
    trackMixPanel
  }
)(withScreenDimensions(Gallery));
