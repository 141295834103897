import React from 'react';
import styles from '../admin.module.css';
import { AdminMainRoutes } from '../../../routes';

const AdminManager = () => {
  return (
    <div className={styles.adminWrapper}>
      <AdminMainRoutes
        navbarClassName={styles.navbarStyle}
        navbarClassNameActive={styles.navBarActiveStyle}
      />
      {/* <Redirect to="/admin/main/uplodaImage" /> */}
    </div>
  );
};

export default AdminManager;
