import {
  spaceBetweenImages,
  screenWidth,
  boxMarginSide,
  numOfImagesPerRows,
  numOfImagesCols,
  imageMaxWidth,
  sortOrder
} from '../constants/responsive.js';

export const calculateAspectRatioFit = (srcWidth, srcHeight, maxWidth, maxHeight) => {
  var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
  return { width: srcWidth * ratio, height: srcHeight * ratio };
};

// export const getWidthGroup = (width, orderVal = false) => {
//   if (!width) return null;
//   if (width <= screenWidth.xs) {
//     // 2 images
//     return !orderVal ? 'xs' : 'orderSmall';
//   } else if (width <= screenWidth.s) {
//     //3 images
//     return !orderVal ? 's' : 'orderSmall';
//   } else if (width <= screenWidth.l) {
//     //3 images
//     return !orderVal ? 'm' : 'orderMeduim';
//   } else if (width <= screenWidth.xl) {
//     //3 images
//     return !orderVal ? 'l' : 'orderLarge';
//   }
//   return !orderVal ? 'xl' : 'orderLarge'; //5 images
// };

export const getWidthGroup = (width, orderVal = false) => {
  if (!width) return null;
  // we want to render the screen when there is a change between screen sizes , *but* there is just
  //3 order value - small ,meduim,large
  if (width <= screenWidth.xs) {
    // 2 images
    return !orderVal ? 'xs' : sortOrder.xs;
  } else if (width <= screenWidth.s) {
    //3 images
    return !orderVal ? 's' : sortOrder.s;
  } else if (width <= screenWidth.m) {
    //3 images
    return !orderVal ? 'm' : sortOrder.m;
  } else if (width <= screenWidth.l) {
    //3 images
    return !orderVal ? 'l' : sortOrder.l;
  } else if (width <= screenWidth.xl) {
    //3 images
    return !orderVal ? 'xl' : sortOrder.xl;
  }
  return !orderVal ? 'xll' : sortOrder.xxl; //5 images
};

export const getGallerySizes = width => {
  let sizes = {};
  if (width <= screenWidth.xs) {
    sizes = {
      numOfImagePerRow: numOfImagesPerRows.xs,
      spaceBetweenImages: spaceBetweenImages.xs,
      boxMarginSide: boxMarginSide.xs,
      numOfCols: numOfImagesCols.xs,
      imageMaxWidth: imageMaxWidth.xs,
      sortOrder: sortOrder.xs
    };
  } else if (width <= screenWidth.s) {
    sizes = {
      numOfImagePerRow: numOfImagesPerRows.s,
      spaceBetweenImages: spaceBetweenImages.s,
      boxMarginSide: boxMarginSide.s,
      numOfCols: numOfImagesCols.s,
      imageMaxWidth: imageMaxWidth.s,
      sortOrder: sortOrder.s
    };
  } else if (width <= screenWidth.m) {
    sizes = {
      numOfImagePerRow: numOfImagesPerRows.m,
      spaceBetweenImages: spaceBetweenImages.m,
      boxMarginSide: boxMarginSide.m,
      numOfCols: numOfImagesCols.m,
      imageMaxWidth: imageMaxWidth.m,
      sortOrder: sortOrder.m
    };
  } else if (width <= screenWidth.l) {
    sizes = {
      numOfImagePerRow: numOfImagesPerRows.l,
      spaceBetweenImages: spaceBetweenImages.l,
      boxMarginSide: boxMarginSide.l,
      numOfCols: numOfImagesCols.l,
      imageMaxWidth: imageMaxWidth.l,
      sortOrder: sortOrder.l
    };
  } else if (width <= screenWidth.xl) {
    sizes = {
      numOfImagePerRow: numOfImagesPerRows.xl,
      spaceBetweenImages: spaceBetweenImages.xl,
      boxMarginSide: boxMarginSide.xl,
      numOfCols: numOfImagesCols.xl,
      imageMaxWidth: imageMaxWidth.xl,
      sortOrder: sortOrder.xl
    };
  } else {
    sizes = {
      numOfImagePerRow: numOfImagesPerRows.xxl,
      spaceBetweenImages: spaceBetweenImages.xxl,
      boxMarginSide: boxMarginSide.xxl,
      numOfCols: numOfImagesCols.xxl,
      imageMaxWidth: imageMaxWidth.xxl,
      sortOrder: sortOrder.xxl
    };
  }
  return sizes;
};

export const sortImagesArrayByOrder = (array, orderVal) => {
  return array ? array.sort((a, b) => a[orderVal] - b[orderVal]) : [];
};
