import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types'; // ES6
import { fetchRecentImages } from '../actions';
import Wrapper from '../../Common/Wrapper';
// import Gallery from '../../Common/Gallery/gallery';
import GalleryNew from '../../Common/Gallery/galleryNew';

import { Loader } from '../../../components/Common/Loader';
import styles from '../recent-images.module.css';

class RecentImagesManager extends Component {
  componentDidMount() {
    this.props.fetchRecentImages();
  }

  render() {
    return (
      <Wrapper additionalClassName={styles.wrapperAdditional}>
        <Loader id="fetchRecentImages">
          <GalleryNew category="Recent" images={this.props.images} />
          {/* <Gallery category="Recent" images={this.props.images} maxImageHeight={150} /> */}
        </Loader>
      </Wrapper>
    );
  }
}

RecentImagesManager.propTypes = {
  fetchRecentImages: PropTypes.func,
  images: PropTypes.array
};

const mapStateToProps = ({ recentImages }) => ({
  images: recentImages.images
});

export default connect(
  mapStateToProps,
  { fetchRecentImages }
)(RecentImagesManager);
