import {
  API_REQUEST,
  ADMIN_FETCH_YEARS_2015_2018_IMAGES,
  ADMIN_UPDATE_YEARS_2015_2018_IMAGES_ORDER,
  ADMIN_SORT_YEARS_2015_2018_IMAGES
} from '../../../constants/requests';

export function fetchYears2015_2018Images() {
  return {
    type: API_REQUEST,
    payload: {
      url: '/images/getyears2015_2018images',
      method: 'get',
      nextActionType: ADMIN_FETCH_YEARS_2015_2018_IMAGES
    }
  };
} 

export function sortYears2015_2018Images(data) {
  return {
    type: ADMIN_SORT_YEARS_2015_2018_IMAGES,
    payload: {
      data
    }
  };
}

export function updateYears2015_2018Images(data) {
  return {
    type: API_REQUEST,
    payload: {
      url: '/images/update2015_2018OImagesOrder',
      method: 'post',
      data,
      nextActionType: ADMIN_UPDATE_YEARS_2015_2018_IMAGES_ORDER
    },
    meta: {
      loaderId: 'updateYears2015_2018Images',
      toast: {
        messageSuccess: 'Images order update successfully',
        messageFailed: 'Images order update failed'
      }
    }
  };
}
