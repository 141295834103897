import React, { Component } from 'react';
import classNames from 'classnames';
import { Row, Col, Button, Icon } from 'react-materialize';
import styles from '../admin.module.css';
import { connect } from 'react-redux';
import { fetchUser } from '../actions';
import { Redirect } from 'react-router';
import PropTypes from 'prop-types';

class AdminSignIn extends Component {
  componentDidMount() {
    this.props.fetchUser();
  }

  render() {
    return !this.props.isAdmin ? (
      <div className={styles.containerAdmin}>
        <Row>
          <Col className={styles.buttonDesign} s={12} m={12} l={12} xl={12}>
            <a href="https://tomer-amir.onrender.com/api/auth/google">
              <Button
                waves="light"
                className={classNames(styles.buttonDesign, '#1a237e indigo darken-4')}
              >
                Sign in with your google account
                <Icon className={styles.iconSize} left>
                  account_circle
                </Icon>
              </Button>
            </a>
          </Col>
        </Row>
      </div>
    ) : (
      <Redirect to="/admin/main" />
    );
  }
}

AdminSignIn.propTypes = {
  fetchUser: PropTypes.func,
  isAdmin: PropTypes.bool
};
const mapStateToProps = ({ admin }) => ({
  isAdmin: admin.isAdmin
});

export default connect(
  mapStateToProps,
  { fetchUser }
)(AdminSignIn);
