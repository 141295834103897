import React from 'react';
import Dropzone from 'react-dropzone';
import styles from './drop-zone.module.css';
import PropTypes from 'prop-types'; // ES6

const DropzoneInput = ({ input, label, labelClass = '', name, meta: { touched, error } }) => {
  const files = input.value;
  return (
    <div>
      {label != null && <span className={labelClass}>{label}</span>}
      <Dropzone
        name={name}
        onDrop={filesToUpload => input.onChange(filesToUpload)}
        multiple={false}
        accept="image/jpeg, image/png"
        className={styles.dropzone}
      >
        <div className={styles.dropzoneText}>Click or drop your image to here </div>
      </Dropzone>
      {touched && error && <div className={styles.error}>{error}</div>}
      {files &&
        Array.isArray(files) && <ul>{files.map((file, i) => <li key={i}>{file.name}</li>)}</ul>}
    </div>
  );
};

DropzoneInput.propTypes = {
  input: PropTypes.any,
  label: PropTypes.string,
  labelClass: PropTypes.string,
  name: PropTypes.string,
  meta: PropTypes.object
};

export default DropzoneInput;
