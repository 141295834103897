import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types'; // ES6
import {
  fetchYears2015_2018Images as fetchyYears2015_2018ImagesAction,
  sortYears2015_2018Images as sortYears2015_2018ImagesAction,
  updateYears2015_2018Images as updateYears2015_2018ImagesAction,
} from '../actions';
import ImagesCategoryEdit from './images-category-edit/images-category-edit';

export const AdminYears20152018Images = ({ images, fetchYears2015_2018Images, sortYears2015_2018Images, updateYears2015_2018Images }) => {
  useEffect(() => {
    fetchYears2015_2018Images();
  }, [fetchYears2015_2018Images])

  const onSortClicked = (images) => sortYears2015_2018Images(images);
  const onSaveClicked = () => updateYears2015_2018Images(images);

  return (
    <ImagesCategoryEdit
      images={images}
      category="2015_2018"
      loaderId="adminfetchYears2015_2018Images"
      onSortClicked={onSortClicked}
      onSaveClicked={onSaveClicked}
    />
  );
}

AdminYears20152018Images.propTypes = {
  images: PropTypes.array,
  fetchYears2015_2018Images: PropTypes.func,
  sortYears2015_2018Images: PropTypes.func,
  updateYears2015_2018Images: PropTypes.func,
};

const mapStateToProps = ({ admin }) => ({
  images: admin["2015_2018"],
  showEditImgWindow: admin.showEditImgWindow
});

export default connect(
  mapStateToProps,
  {
    fetchYears2015_2018Images: fetchyYears2015_2018ImagesAction,
    sortYears2015_2018Images: sortYears2015_2018ImagesAction,
    updateYears2015_2018Images: updateYears2015_2018ImagesAction
  }
)(AdminYears20152018Images);
