export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';
export const HIDE_ALL_LOADERS = 'HIDE_ALL_LOADERS';

export function showLoader(actionName) {
  return {
    type: SHOW_LOADER,
    payload: {
      id: actionName
    }
  };
}

export function hideLoader(actionName) {
  return {
    type: HIDE_LOADER,
    payload: {
      id: actionName
    }
  };
}

export function hideAllLoaders() {
  return {
    type: HIDE_ALL_LOADERS
  };
}
