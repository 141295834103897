import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchYears2015_2018Images as fetchYears2015_2018ImagesAction } from '../actions';
import Wrapper from '../../Common/Wrapper';
import { Loader } from '../../../components/Common/Loader';
import GalleryNew from '../../Common/Gallery/galleryNew';

export const Years20152018ImagesManager = ({ fetchYears2015_2018Images, images }) => {
  useEffect(() => {
    fetchYears2015_2018Images();
  }, [fetchYears2015_2018Images]);
  return (
    <Wrapper>
      <Loader id="fetchYears2015_2018Images">
        <GalleryNew category="2015_2018" images={images} />
      </Loader>
    </Wrapper>
  );
};

Years20152018ImagesManager.propTypes = {
  fetchYears2015_2018Images: PropTypes.func,
  images: PropTypes.array
};

const mapStateToProps = ({ years2015_2018 }) => ({
  images: years2015_2018.images
});

export default connect(
  mapStateToProps,
  { fetchYears2015_2018Images: fetchYears2015_2018ImagesAction }
)(Years20152018ImagesManager);
