import React from 'react';
// import styles from './wrapper.module.css';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import DivWrapper from '../Div-Wrapper';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  font-family: 'Poiret One';
  color: white;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background: ${props => props.theme.colors.sitePrimary};
`;

const Wrapper = ({ children, additionalClassNames = '' }) => (
  <DivWrapper minHeight element="wrapper">
    <StyledWrapper className={classNames('rowAlign', additionalClassNames)}>
      {children}
    </StyledWrapper>
  </DivWrapper>
);

Wrapper.propTypes = {
  children: PropTypes.node,
  additionalClassNames: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};

export default Wrapper;
