import {
  API_REQUEST,
  ADMIN_FETCH_EARLIER_IMAGES,
  ADMIN_UPDATE_EARLIER_IMAGES_ORDER,
  ADMIN_SORT_EARLIER_IMAGES
} from '../../../constants/requests';

export function fetchEarlierImages() {
  return {
    type: API_REQUEST,
    payload: {
      url: '/images/getEarlierImages',
      method: 'get',
      nextActionType: ADMIN_FETCH_EARLIER_IMAGES
    }
  };
}

export function sortEarlierImages(data) {
  return {
    type: ADMIN_SORT_EARLIER_IMAGES,
    payload: {
      data
    }
  };
}

export function updateEarlierImages(data) {
  return {
    type: API_REQUEST,
    payload: {
      url: '/images/updateEarlierImagesOrder',
      method: 'post',
      data,
      nextActionType: ADMIN_UPDATE_EARLIER_IMAGES_ORDER
    },
    meta: {
      loaderId: 'updateEarlierImages',
      toast: {
        messageSuccess: 'Images order update successfully',
        messageFailed: 'Images order update failed'
      }
    }
  };
}
