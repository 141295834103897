import {
  FETCH_USER,
  ADMIN_FETCH_RECENT_IMAGES,
  ADMIN_SORT_RECENT_IMAGES,
  ADMIN_UPDATE_IMAGE,
  ADMIN_CHANGE_IMG_EDIT_WINDOW_STATUS,
  ADMIN_DELETE_IMAGE,
  ADMIN_FETCH_EARLIER_IMAGES,
  ADMIN_UPDATE_EARLIER_IMAGES_ORDER,
  ADMIN_SORT_EARLIER_IMAGES,
  ADMIN_GET_USERS,
  ADMIN_ADD_ADMIN,
  ADMIN_FETCH_YEARS_2015_2018_IMAGES,
  // ADMIN_UPDATE_YEARS_2015_2018_IMAGES_ORDER,
  ADMIN_SORT_YEARS_2015_2018_IMAGES
} from '../../../constants/requests';

const initialState = {
  isAdmin: false,
  recentImages: [],
  earlierImages: [],
  "2015_2018":[],
  users: [],
  showEditImgWindow: false
};
export const AdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER.success: {
      return {
        ...state,
        isAdmin: action.payload.data.admin
      };
    }
    case ADMIN_FETCH_RECENT_IMAGES.success: {
      return {
        ...state,
        recentImages: action.payload.data.data
      };
    }
    case ADMIN_SORT_RECENT_IMAGES: {
      return {
        ...state,
        recentImages: action.payload.data
      };
    }

    case ADMIN_FETCH_YEARS_2015_2018_IMAGES.success: {
      return {
        ...state,
        "2015_2018": action.payload.data.images
      };
    }

    case ADMIN_SORT_YEARS_2015_2018_IMAGES: {
      return {
        ...state,
        "2015_2018": action.payload.data
      };
    }

    case ADMIN_UPDATE_IMAGE.success: {
      let field = null;
      if (action.meta.imageCategory === 'recent') {
        field = 'recentImages';
      } else if (action.meta.imageCategory === 'earlier') {
        field = 'earlierImages';
      } else if (action.meta.imageCategory === '2015_2018') {
        field = '2015_2018';
      }

      return {
        ...state,
        showEditImgWindow: false,
        [field]: state[field].map(
          img => (img._id === action.payload.data.data._id ? action.payload.data.data : img)
        )
      };
    }
    case ADMIN_CHANGE_IMG_EDIT_WINDOW_STATUS: {
      return {
        ...state,
        showEditImgWindow: !state.showEditImgWindow
      };
    }
    case ADMIN_DELETE_IMAGE.success: {
      let field = null;
      if (action.meta.imageCategory === 'recent') {
        field = 'recentImages';
      } else if (action.meta.imageCategory === 'earlier') {
        field = 'earlierImages';
      }
      else if (action.meta.imageCategory === '2015_2018') {
        field = '2015_2018';
      }
      return {
        ...state,
        showEditImgWindow: false,
        [field]: action.payload.data.images
      };
    }
    case ADMIN_DELETE_IMAGE.error: {
      return {
        ...state,
        showEditImgWindow: true
      };
    }
    case ADMIN_FETCH_EARLIER_IMAGES.success: {
      return {
        ...state,
        earlierImages: action.payload.data.images
      };
    }
    case ADMIN_UPDATE_EARLIER_IMAGES_ORDER: {
      break;
    }
    case ADMIN_SORT_EARLIER_IMAGES: {
      return {
        ...state,
        earlierImages: action.payload.data
      };
    }
    case ADMIN_GET_USERS.success: {
      return {
        ...state,
        users: action.payload.data.users
      };
    }
    case ADMIN_ADD_ADMIN.success: {
      const admin = action.payload.data.admin;
      return {
        ...state,
        users: state.users.map(
          user =>
            user.googleID === admin.googleID && admin.emailAddress === user.emailAddress
              ? { ...user, admin: false }
              : user
        )
      };
    }

    default:
      return state;
  }
};

export default AdminReducer;
