import React, { Component } from 'react';
import styles from '../main.module.css';
import Wrapper from '../../Common/Wrapper';
import { Row, Col } from 'react-materialize';
import Responsive from '../../Common/Responsive';
import { screenWidth } from '../../../constants/responsive';
import MainImage from './main-image';
import { trackMixPanel } from '../../common-actions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class Main extends Component {
  componentDidMount() {
    this.props.trackMixPanel({
      actionName: 'MainPage'
    });
  }
  render() {
    return (
      <Wrapper additionalClassNames={styles.wrapperAdd}>
        <Row>
          <Responsive
            minDeviceWidth={0}
            maxDeviceWidth={screenWidth.s}
            className={styles.imageWrapper}
          >
            <MainImage
              className={styles.mainImage}
              image={{
                src: require('../../../assets/images/mainImage.jpg'),
                alt: 'mainImage'
              }}
            />
          </Responsive>

          <Responsive
            minDeviceWidth={screenWidth.s}
            maxDeviceWidth={Number.MAX_VALUE}
            className={styles.flex}
          >
            <Col m={6} l={3} xl={3} className={styles.imageWrapper}>
              <MainImage
                className={styles.mainImage}
                image={{
                  src: require('../../../assets/images/mainImage.jpg'),
                  alt: 'mainImage'
                }}
              />
            </Col>

            <Col m={6} l={9} xl={9} className={styles.mainText}>
              <div>
                In my paintings I try to create a world, like in literature, in music and in poetry.
              </div>
              <div>I want to introduce the viewer into my inner world.</div>
            </Col>
          </Responsive>
        </Row>
      </Wrapper>
    );
  }
}

Main.propTypes = {
  trackMixPanel: PropTypes.func
};

export default connect(
  null,
  {
    trackMixPanel
  }
)(Main);
