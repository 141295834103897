import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button, Row, Col } from 'react-materialize';
import { required } from '../../../../utils/validate';
import { connect } from 'react-redux';
import InputField from '../../../Common/Input/input-field';
import { uploadImages, updateImage, changeImgEditWindowStatus, deleteImage } from '../../actions';
import PropTypes from 'prop-types';
import { Loader } from '../../../../components/Common/Loader';
import styles from './img-details-edit.module.css';
import classNames from 'classnames';

class ImgDetailsEdit extends Component {
  // clickHandler(e) {
  //   if (e.target.className.indexOf('imgDetailsEdit') > -1) {
  //     this.props.changeImgEditWindowStatus();
  //   }
  // }
  componentDidMount() {
    const { change, image } = this.props;
    change('imageName', image.name);
    change('imageDescription', image.description);
    change('imageOrderSmall', image.orderSmall);
    change('imageOrderMeduim', image.orderMeduim);
    change('imageOrderLarge', image.orderLarge);
    this.onDelete = this.onDelete.bind(this);
  }

  onSubmit = values => {
    this.props.updateImage({
      imageCategory: this.props.category,
      image: {
        ...this.props.image,
        name: values.imageName,
        description: values.imageDescription,
        orderSmall: values.imageOrderSmall,
        orderMeduim: values.imageOrderMeduim,
        orderLarge: values.imageOrderLarge
      }
    });
  };

  onDelete() {
    const { category, image } = this.props;

    this.props.deleteImage({
      imageCategory: category,
      imageId: this.props.image._id,
      orderSmall: image.orderSmall,
      orderMeduim: image.orderMeduim,
      orderLarge: image.orderLarge
    });
  }

  render() {
    return (
      this.props.showEditImgWindow && (
        <div className={classNames(styles.modal, 'imgDetailsEdit')}>
          <div className={styles['modal-content']}>
            <span onClick={() => this.props.changeImgEditWindowStatus()} className={styles.close}>
              &times;
            </span>
            <div className={styles.title}>Edit Image Details</div>,
            <Loader>
              <div className="formDiv">
                <Field
                  name="imageName"
                  type="text"
                  component={InputField}
                  label="Image Name"
                  labelClass={styles.labelStyle}
                  inputClass={styles.textStyle}
                  validate={required}
                  fieldWwrapName={styles.editField}
                />
                <Field
                  name="imageDescription"
                  type="text"
                  component={InputField}
                  label="Image description"
                  labelClass={styles.labelStyle}
                  inputClass={styles.textStyle}
                  validate={required}
                  fieldWwrapName={styles.editField}
                />

                <Row>
                  <Col s={4} m={3} l={3} xl={3}>
                    <Field
                      name="imageOrderSmall"
                      type="number"
                      component={InputField}
                      label="Order Small"
                      labelClass={styles.labelStyle}
                      inputClass={styles.inputStyle}
                      validate={required}
                      fieldWwrapName={styles.editField}
                    />
                  </Col>
                  <Col s={4} m={3} l={3} xl={3}>
                    <Field
                      name="imageOrderMeduim"
                      type="number"
                      component={InputField}
                      label="Order Meduim"
                      labelClass={styles.labelStyle}
                      inputClass={styles.inputStyle}
                      validate={required}
                      fieldWwrapName={styles.editField}
                    />
                  </Col>
                  <Col s={4} m={3} l={3} xl={3}>
                    <Field
                      name="imageOrderLarge"
                      type="number"
                      component={InputField}
                      label="Order Large"
                      labelClass={styles.labelStyle}
                      inputClass={styles.inputStyle}
                      validate={required}
                      fieldWwrapName={styles.editField}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col s={3} m={3} l={3} xl={3} className={styles.test}>
                    <Button onClick={this.props.handleSubmit(this.onSubmit)}>Update</Button>
                  </Col>

                  <Col s={3} m={3} l={3} xl={3} className={styles.test} offset={'s5 m6 l6 xl6'}>
                    <Button className={styles.deleteButton} onClick={this.onDelete}>
                      Delete
                    </Button>
                  </Col>
                </Row>
              </div>
            </Loader>
          </div>
        </div>
      )
    );
  }
}

ImgDetailsEdit.propTypes = {
  // uploadImages: PropTypes.func,
  // currentImageIdClicked: PropTypes.object,
  updateImage: PropTypes.func,
  category: PropTypes.string,
  showEditImgWindow: PropTypes.bool,
  deleteImage: PropTypes.func,
  change: PropTypes.func,
  handleSubmit: PropTypes.func,
  changeImgEditWindowStatus: PropTypes.func,
  image: PropTypes.object
};

const mapStateToProps = ({ admin }) => ({
  showEditImgWindow: admin.showEditImgWindow
});

export default connect(
  mapStateToProps,
  { uploadImages, updateImage, changeImgEditWindowStatus, deleteImage }
)(
  reduxForm({
    form: 'ImgDetailsEdit',
    touchOnChange: true
  })(ImgDetailsEdit)
);
