import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { changeImgEditWindowStatus } from '../../actions';
import GalleryEditable from '../../../Common/Gallery/gallery-editable';
import Gallery from '../../../Common/Gallery/gallery';
import { Loader } from '../../../../components/Common/Loader';
import { Button, Row, Col } from 'react-materialize';
import styles from './images-category-edit.module.css';
import ImgDetailsEdit from '../img-details-edit/img-details-edit';
import withScreenDimensions from '../../../../hoc/with-screen-dimensions';

class ImagesCategoryEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPreviewGallery: false,
      currentImageIdClicked: null
    };

    // this.clickHandler = this.clickHandler.bind(this);
    this.onPreviewClicked = this.onPreviewClicked.bind(this);
    this.onImageClick = this.onImageClick.bind(this);

    //  window.addEventListener('click', this.clickHandler);
  }

  // clickHandler(e) {
  //   // if (e.target.className.indexOf('imgDetailsEdit') > -1) {
  //   // this.props.changeImgEditWindowStatus();
  //   // }
  // }

  onPreviewClicked() {
    this.setState({
      showPreviewGallery: !this.state.showPreviewGallery
    });
  }

  onImageClick(imageId) {
    this.setState({
      currentImageIdClicked: imageId
    });

    this.props.changeImgEditWindowStatus();
  }

  //get images real order group
  getWidthGroupOrder() {
    switch (this.props.widthGroup) {
      case 'xs':
      case 's':
        return 's';
      default:
        return 'l';
    }
  }
  render() {
    return [
      <Row className={styles.orderRow} key={0}>
        <Col s={3} m={3} l={3} xl={3} offset={'s3 m5 l5 xl5'}>
          Order Size:{' '}
          <span className={styles.orderSize}>{` ${this.getWidthGroupOrder().toUpperCase()}`}</span>
        </Col>
      </Row>,
      <Row key={1} className={styles.editRow}>
        <Col s={3} m={3} l={3} xl={3} offset={'s3 m3 l3 xl3'}>
          <Button className={styles.buttonWidth} onClick={this.props.onSaveClicked}>
            Save
          </Button>
        </Col>
        <Col s={3} m={3} l={3} xl={3}>
          <Button onClick={this.onPreviewClicked} className={styles.buttonWidth}>
            {!this.state.showPreviewGallery ? 'Preview' : 'Back to edit'}
          </Button>
        </Col>
      </Row>,
      this.state.showPreviewGallery &&
        !this.props.showEditImgWindow && <Gallery key={2} images={this.props.images} />,
      !this.state.showPreviewGallery &&
        !this.props.showEditImgWindow && (
          <Loader key={3} id={this.props.loaderId}>
            <GalleryEditable
              images={this.props.images}
              onSort={this.props.onSortClicked}
              onImageClick={this.onImageClick}
            />
          </Loader>
        ),
      this.props.showEditImgWindow && (
        <ImgDetailsEdit
          key={4}
          category={this.props.category}
          image={
            this.state.currentImageIdClicked &&
            this.props.images.filter(img => img._id === this.state.currentImageIdClicked)[0]
          }
        />
      )
    ];
  }
}

ImagesCategoryEdit.propTypes = {
  showEditImgWindow: PropTypes.bool,
  changeImgEditWindowStatus: PropTypes.func,
  loaderId: PropTypes.string,
  category: PropTypes.string,
  onSortClicked: PropTypes.func,
  onSaveClicked: PropTypes.func,
  images: PropTypes.array,
  widthGroup: PropTypes.string
};

const mapStateToProps = ({ admin }) => ({
  showEditImgWindow: admin.showEditImgWindow
});

export default connect(
  mapStateToProps,
  {
    changeImgEditWindowStatus
  }
)(withScreenDimensions(ImagesCategoryEdit));
