import React from 'react';
import styles from '../admin.module.css';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

const AdminHeader = ({ children }) => (
  <div>
    <div className={styles.container}>
      <div className={styles.logoAdmin} />
      <div className={styles.centered}>Admin</div>
    </div>
    <div>{children}</div>
  </div>
);

AdminHeader.propTypes = {
  children: PropTypes.any
};

export default withRouter(AdminHeader);
