import React, { Component } from 'react';
import { getWidthGroup } from '../utils/images-util.js';

const withScreenDimensions = (WrappedComponent, alwaysUpdate = false) => {
  return class ScreenDimensions extends Component {
    constructor(props) {
      super(props);
      this.state = {
        height: window.innerHeight,
        width: window.innerWidth,
        widthGroup: '',
        widthGroupOrder: ''
      };
      this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentDidMount() {
      window.addEventListener('resize', this.updateDimensions);
      this.setState({
        widthGroup: getWidthGroup(this.state.width)
        // widthGroupOrder: getWidthGroup(this.state.width, true)
      });
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.updateDimensions);
    }

    updateDimensions() {
      const w = window,
        d = document,
        documentElement = d.documentElement,
        body = d.getElementsByTagName('body')[0],
        width = w.innerWidth || documentElement.clientWidth || body.clientWidth,
        height = w.innerHeight || documentElement.clientHeight || body.clientHeight;
      if (alwaysUpdate) {
        this.setState({
          width,
          height,
          widthGroup: getWidthGroup(width)
        });
      } else if (getWidthGroup(this.state.width) !== getWidthGroup(width)) {
        this.setState({
          width,
          height,
          widthGroup: getWidthGroup(width)
        });
      }

      // this.setState({ ...this.state, height: window.innerHeight, width: window.innerWidth });
    }

    render() {
      return (
        <WrappedComponent
          screenHeight={this.state.height}
          screenWidth={this.state.width}
          widthGroup={this.state.widthGroup}
          {...this.props}
        />
      );
    }
  };
};

export default withScreenDimensions;
