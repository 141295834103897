import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  fetchEarlierImages,
  sortEarlierImages,
  updateEarlierImages,
  changeImgEditWindowStatus
} from '../actions';
import ImagesCategoryEdit from './images-category-edit/images-category-edit';

class AdminEarlierImages extends Component {
  componentDidMount() {
    this.props.fetchEarlierImages();
  }

  onSortClicked(images) {
    this.props.sortEarlierImages(images);
  }

  onSaveClicked() {
    this.props.updateEarlierImages(this.props.images);
  }

  render() {
    return (
      <ImagesCategoryEdit
        images={this.props.images}
        category="earlier"
        loaderId="adminfetchRecentImages"
        onSortClicked={this.onSortClicked.bind(this)}
        onSaveClicked={this.onSaveClicked.bind(this)}
      />
    );
  }
}

AdminEarlierImages.propTypes = {
  fetchEarlierImages: PropTypes.func,
  images: PropTypes.array,
  sortEarlierImages: PropTypes.func,
  updateEarlierImages: PropTypes.func
};

const mapStateToProps = ({ admin }) => ({
  images: admin.earlierImages,
  showEditImgWindow: admin.showEditImgWindow
});

export default connect(
  mapStateToProps,
  {
    fetchEarlierImages,
    sortEarlierImages,
    updateEarlierImages,
    changeImgEditWindowStatus
  }
)(AdminEarlierImages);
