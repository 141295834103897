import React from 'react';
import { AdminManager, AdminSignIn } from '../components/Admin';
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from '../components/Common/Routes/private-route';

const AdminRoutes = () => {
  return (
    <div>
      <Switch>
        <Route exact path="/admin" component={AdminSignIn} />
        <PrivateRoute path="/admin/main" component={AdminManager} />
      </Switch>
    </div>
  );
};

export default AdminRoutes;
