import React from 'react';
import { Row, Col } from 'react-materialize';
import PropTypes from 'prop-types';

export const Content = ({ children, className }) => (
  <Row>
    <Col className={className}>{children}</Col>
  </Row>
);

Content.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default Content;
