import { takeEvery, put } from 'redux-saga/effects';
import {
  SEND_CONTACT_US_MAIL,
  FETCH_RECENT_IMAGES,
  FILES_UPLOAD,
  ADMIN_FETCH_RECENT_IMAGES,
  ADMIN_UPDATE_IMAGE,
  FETCH_EARLIER_IMAGES,
  FETCH_YEARS_2015_2018_IMAGES
} from '../constants/requests';
import { actions as loaderActions } from '../components/Common/Loader';

function* showLoader(action) {
  if (action.meta.loaderId) {
    yield put(loaderActions.showLoader(action.meta.loaderId));
  }
}

function* hideLoader(action) {
  if (action.meta.loaderId) {
    yield put(loaderActions.hideLoader(action.meta.loaderId));
  }
}

export default function* loaderSaga() {
  yield takeEvery(
    [
      SEND_CONTACT_US_MAIL.success,
      SEND_CONTACT_US_MAIL.error,
      FETCH_RECENT_IMAGES.error,
      FETCH_RECENT_IMAGES.success,
      FILES_UPLOAD.success,
      FILES_UPLOAD.error,
      ADMIN_FETCH_RECENT_IMAGES.success,
      ADMIN_FETCH_RECENT_IMAGES.error,
      ADMIN_UPDATE_IMAGE.success,
      ADMIN_UPDATE_IMAGE.error,
      FETCH_EARLIER_IMAGES.error,
      FETCH_EARLIER_IMAGES.success,
      FETCH_YEARS_2015_2018_IMAGES.error,
      FETCH_YEARS_2015_2018_IMAGES.success
    ],
    hideLoader
  );
  yield takeEvery(
    [
      SEND_CONTACT_US_MAIL.pending,
      FETCH_RECENT_IMAGES.pending,
      FILES_UPLOAD.pending,
      ADMIN_FETCH_RECENT_IMAGES.pending,
      ADMIN_UPDATE_IMAGE.pending,
      FETCH_EARLIER_IMAGES.pending,
      FETCH_YEARS_2015_2018_IMAGES.pending
    ],
    showLoader
  );
}
