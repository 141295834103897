import mixpanel from 'mixpanel-browser';
import { takeEvery, call } from 'redux-saga/effects';

function* trackMixpanel(action) {
  yield call(() => {
    action.payload.mixPanelObjects.forEach(element => mixpanel.track(element.actionName, element));
  });
}

export default function* mixpanelSaga() {
  yield takeEvery('APP_TRACK_MIX_PANEL', trackMixpanel);
}
