import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import styles from './gallery.module.css';
import {
  calculateAspectRatioFit,
  getGallerySizes,
  sortImagesArrayByOrder
} from '../../../utils/images-util';
import classNames from 'classnames';
import { Row, Col } from 'react-materialize';
import ImageCaption from './img-caption';
import { trackMixPanel } from '../../common-actions';
import DivWrapper from '../Div-Wrapper';
import { connect } from 'react-redux';
import withScreenDimensions from '../../../hoc/with-screen-dimensions';
import { screenWidth as screenWidthConstants } from '../../../constants/responsive';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import styled from 'styled-components';
import { changeImageStatus } from '../../common-actions';

const GalleryWrapper = styled.div`
  margin-right: ${props => props.boxMarginSide || 0}px;
  margin-left: ${props => props.boxMarginSide || 0}px;
`;

const ImgElment = styled.img.attrs(props => ({
  src: props.imageSrc
}))`
  max-width: ${props => (props.windowWidth > 1600 ? 55 : props.imgMaxWidth)}%;
  height: auto;
  margin-bottom: ${props => props.marginBottom || 0}px;
`;

class GalleryNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photoIndex: 0,
      isOpen: false,
      containerWidth: null
    };

    this.onLightBoxClosed = this.onLightBoxClosed.bind(this);
    this.onLightBoxNext = this.onLightBoxNext.bind(this);
    this.onlightBoxPrev = this.onlightBoxPrev.bind(this);

    this.imagesConatiner = React.createRef();
  }

  onLightBoxClosed() {
    this.setState({ isOpen: false });
    enableBodyScroll(this.imagesConatiner.current);
  }

  onLightBoxNext() {
    this.setState({
      photoIndex: this.state.photoIndex + 1
    });
  }

  onlightBoxPrev() {
    this.setState({
      photoIndex: this.state.photoIndex - 1
    });
  }

  componentDidMount() {
    if (this.imagesConatiner.current && this.imagesConatiner.current.clientWidth) {
      this.setState({ ...this.state, containerWidth: this.imagesConatiner.current.clientWidth });
    }
  }

  imgOnClick(index) {
    disableBodyScroll(this.imagesConatiner.current);
    this.setState({
      isOpen: true,
      photoIndex: index
    });

    //track mix panel event
    if (this.props.category) {
      this.props.trackMixPanel({
        actionName: 'Gallery_image_click',
        category: this.props.category
      });
    }
  }
  render() {
    const windowWidth = this.props.screenWidth;
    const gallerySizes = getGallerySizes(windowWidth);

    const { images, maxImageHeight } = this.props;

    let imgMaxWidth = null;

    const imagesSet = sortImagesArrayByOrder(images, gallerySizes.sortOrder).map((img, index) => ({
      src: img.url,
      thumbnail: img.url,
      thumbnailWidth: calculateAspectRatioFit(
        img.width,
        img.height,
        imgMaxWidth ? imgMaxWidth : img.width,
        this.props.maxImageHeight ? maxImageHeight : 300
      ).width,
      thumbnailHeight: calculateAspectRatioFit(
        img.width,
        img.height,
        imgMaxWidth ? imgMaxWidth : img.width,
        this.props.maxImageHeight ? maxImageHeight : 300
      ).height,
      caption: <ImageCaption key={index} imgName={img.name} imgDescription={img.description} />,
      index,
      key: index,
      id: img.public_id
    }));

    const imagesCols = imagesSet.reduce((total, cur, index) => {
      return total[index % gallerySizes.numOfCols]
        ? {
            ...total,
            [index % gallerySizes.numOfCols]: [...total[index % gallerySizes.numOfCols], cur]
          }
        : { ...total, [index % gallerySizes.numOfCols]: [cur] };
    }, {});
    const imgPrecentSize =
      (100 / gallerySizes.numOfCols) * ((100 - gallerySizes.imageMaxWidth) / 100);
    const marginTopImages =
      this.state.containerWidth && this.state.containerWidth * (imgPrecentSize / 100) + 10;

    return (
      <DivWrapper minHeight element="imagesContainer">
        <GalleryWrapper
          className={classNames(styles.imgWrap, 'mainGallery')}
          ref={this.imagesConatiner}
          boxMarginSide={gallerySizes.boxMarginSide}
        >
          {/* <div
          //   style={{
          //     marginRight: `${gallerySizes.boxMarginSide}px`,
          //     marginLeft: `${gallerySizes.boxMarginSide}px`
          //     // overflow: this.state.isOpen && 'hidden',
          //     // position: this.state.isOpen && 'relative',
          //     // height: this.state.isOpen && '100%'
          //   }}
          > */}
          {this.state.isOpen && (
            <Lightbox
              reactModalProps={{ shouldReturnFocusAfterClose: false }}
              mainSrc={imagesSet.map(img => img.src)[this.state.photoIndex]}
              nextSrc={
                imagesSet.length > this.state.photoIndex + 1
                  ? imagesSet[this.state.photoIndex + 1].src
                  : null
              }
              prevSrc={this.state.photoIndex > 0 ? imagesSet[this.state.photoIndex - 1].src : null}
              imageCaption={imagesSet[this.state.photoIndex].caption}
              enableZoom={false}
              imagePadding={this.props.screenWidth > screenWidthConstants.xs ? 60 : 50}
              onCloseRequest={this.onLightBoxClosed}
              onMovePrevRequest={this.onlightBoxPrev}
              onMoveNextRequest={this.onLightBoxNext}
              clickOutsideToClose={false}
              animationDisabled={true}
            />
          )}
          <Row>
            {Object.keys(imagesCols).map((key, index) => (
              <Col
                key={index}
                style={{
                  width: `${100 / gallerySizes.numOfCols}%`
                }}
              >
                {imagesCols[key].map((img, i) => (
                  <Row
                    key={i}
                    style={{
                      marginTop: `${marginTopImages}px`
                    }}
                  >
                    <ImgElment
                      imageSrc={
                        this.props.images.length > 0 &&
                        this.props.images.filter(item => item.public_id === img.id)[0].load
                          ? img.src
                          : require('../../../assets/images/loader-gif.gif')
                      }
                      onLoad={() => this.props.changeImageStatus(img.id)}
                      alt={img.caption}
                      className={styles.imgStyle}
                      key={i}
                      onClick={() => this.imgOnClick(img.index)}
                      windowWidth={windowWidth}
                      imgMaxWidth={gallerySizes.imageMaxWidth}
                      marginBottom={i + 1 === imagesCols[key].length ? marginTopImages : 0}
                    />

                    {/* <img
                      src={
                        this.props.images.length > 0 &&
                        this.props.images.filter(item => item.public_id === img.id)[0].load
                          ? img.src
                          : require('../../../assets/images/loader-git.gif')
                      }
                      onLoad={() => this.props.changeImageStatus(img.id)}
                      alt={img.caption}
                      className={styles.imgStyle}
                      style={
                        i + 1 === imagesCols[key].length
                          ? {
                              maxWidth:
                                windowWidth > 1600 ? '55%' : `${gallerySizes.imageMaxWidth}%`,
                              height: 'auto',
                              marginBottom: `${marginTopImages}px`
                            }
                          : {
                              maxWidth:
                                windowWidth > 1600 ? '55%' : `${gallerySizes.imageMaxWidth}%`,
                              height: 'auto'
                            }
                      }
                      key={i}
                      onClick={() => this.imgOnClick(img.index)}
                    /> */}
                  </Row>
                ))}
              </Col>
            ))}
          </Row>
          {/* </div> */}
        </GalleryWrapper>
      </DivWrapper>
    );
  }
}

GalleryNew.propTypes = {
  images: PropTypes.array,
  maxImageHeight: PropTypes.number,
  trackMixPanel: PropTypes.func,
  category: PropTypes.string,
  screenWidth: PropTypes.number,
  changeImageStatus: PropTypes.func
};

export default connect(
  null,
  {
    trackMixPanel,
    changeImageStatus
  }
)(withScreenDimensions(GalleryNew));
