import React from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import {
  AdminPage,
  //AdminLogInPage,
  MainPage,
  HeaderPage,
  RecentImagesManagerPage,
  EarlierImagesManagerPage,
  FooterPage,
  CvPage,
  ContactPage,
  NotFoundPage,
  Years20152018ImagesManagerPage
} from '../pages';

const MainRoutes = () => (
  <BrowserRouter>
    <div>
      <Route
        render={({ location }) => {
          return location.pathname.includes('admin') ? '' : <HeaderPage location={location} />;
        }}
      />
      <Switch>
        <Route exact path="/" component={MainPage} />
        <Route exact path="/" component={MainPage} />
        <Route exact path="/recentWork" component={RecentImagesManagerPage} />
        <Route exact path="/earlierWork" component={EarlierImagesManagerPage} />
        <Route exact path="/2015_2018" component={Years20152018ImagesManagerPage} />
        <Route exact path="/cv" component={CvPage} />
        <Route exact path="/contact" component={ContactPage} />
        <Route path="/admin" component={AdminPage} />
        <Route component={NotFoundPage} />
      </Switch>
      <Route
        render={({ location }) => {
          return location.pathname.includes('admin') ? '' : <FooterPage />;
        }}
      />
    </div>
  </BrowserRouter>
);

export default MainRoutes;
