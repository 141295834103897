import React from 'react';
import PropTypes from 'prop-types';
import withScreenDimensions from '../../../hoc/with-screen-dimensions';

const Responsive = ({ screenWidth, minDeviceWidth, maxDeviceWidth, children, className }) =>
  (minDeviceWidth || minDeviceWidth === 0) &&
  maxDeviceWidth &&
  screenWidth >= minDeviceWidth &&
  screenWidth <= maxDeviceWidth && <div className={className}>{children}</div>;

Responsive.propTypes = {
  minDeviceWidth: PropTypes.number,
  maxDeviceWidth: PropTypes.number,
  className: PropTypes.string,
  children: PropTypes.any,
  screenWidth: PropTypes.number
};

export default withScreenDimensions(Responsive);
