import React from 'react';
import styles from './footer.module.css';
import { Row } from 'react-materialize';
import classNames from 'classnames';
import DivWrapper from '../Common/Div-Wrapper';

export const Footer = () => (
  <DivWrapper minHeight element="footer">
    <div className={classNames(styles.footerDesign, 'footerMain')}>
      <Row className={classNames(styles.footerText, styles.copyRightRow)}>
        © All copyrights reserved to Tomer-Amir 2019
      </Row>

      <Row className={styles.footerText}>
        <span className={styles.footerInstgramText}>Follow me on </span>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.instagram.com/tomer.amir90210/?hl=en"
        >
          <img
            className={styles.imgIns}
            alt="instagremImg"
            src={require('../../assets/images/ins.png')}
          />
        </a>
      </Row>
    </div>
  </DivWrapper>
);

export default Footer;
