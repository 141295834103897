import React from 'react';
import PropTypes from 'prop-types';
import styles from './gallery.module.css';
import { getGallerySizes, sortImagesArrayByOrder, getWidthGroup } from '../../../utils/images-util';
import classNames from 'classnames';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import withScreenDimensions from '../../../hoc/with-screen-dimensions';

export const GalleryEditable = ({ images, onSort, onImageClick, screenWidth }) => {
  const onSortEnd = ({ oldIndex, newIndex }) => {
    const imgOrder = getWidthGroup(screenWidth, true);
    onSort(
      arrayMove(
        images.map((img, index) => {
          if (index === oldIndex) return { ...img, [imgOrder]: newIndex + 1 };
          else if (index === newIndex) return { ...img, [imgOrder]: oldIndex + 1 };
          return img;
        }),
        oldIndex,
        newIndex
      )
    );
  };

  const gallerySizes = getGallerySizes(screenWidth);
  const imagesSet = sortImagesArrayByOrder(images, gallerySizes.sortOrder);
  const SortableItem = SortableElement(img => (
    <img
      src={img.value.url}
      alt={img.caption}
      className={styles.imgStyle}
      style={{
        marginTop: '50px',
        maxWidth: `${gallerySizes.imageMaxWidth}%`,
        height: 'auto'
      }}
      onClick={() => onImageClick(img.value._id)}
    />
  ));

  const SortableList = SortableContainer(({ items }) => (
    <div
      style={{
        gridTemplateColumns: `repeat(${gallerySizes.numOfCols}, 1fr)`,
        marginLeft: `5px`,
        marginRight: '5px'
      }}
      className={classNames(styles.imgWrap, 'galleryRow', styles.galleryGrid)}
    >
      {items.map((img, index) => <SortableItem key={`img-${index}`} index={index} value={img} />)}
    </div>
  ));

  return (
    imagesSet.length > 0 && (
      <div
        style={{
          marginRight: `${gallerySizes.boxMarginSide}px`,
          marginLeft: `${gallerySizes.boxMarginSide}px`
        }}
        className={classNames(styles.imgWrap, 'galleryRow')}
      >
        <SortableList pressDelay={100} axis="xy" items={imagesSet} onSortEnd={onSortEnd} />
      </div>
    )
  );
};

GalleryEditable.propTypes = {
  images: PropTypes.array,
  onSort: PropTypes.func,
  onImageClick: PropTypes.func,
  screenWidth: PropTypes.number
};

export default withScreenDimensions(GalleryEditable);
