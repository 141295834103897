import React, { Component } from 'react';
import PropTypes from 'prop-types'; // ES6
import DivWrapper from '../../Common/Div-Wrapper';

// export const MainImage = ({ image, className = '' }) => (
//   <DivWrapper element="wrapper" padding>
//     <img
//       src={require('../../../assets/images/loader-git.gif')}
//       onload={() => {
//         debugger;
//       }}
//       className={className}
//       alt={image.alt}
//     />
//   </DivWrapper>
// );

class MainImage extends Component {
  constructor(props) {
    super(props);
    this.state = { loaded: false };
  }
  render() {
    return (
      <DivWrapper element="wrapper" padding>
        <img
          src={
            this.state.loaded
              ? this.props.image.src
              : require('../../../assets/images/mainImage_low.jpg')
          }
          onLoad={() => this.setState({ loaded: true })}
          className={this.props.className}
          alt={this.props.image.alt}
        />
      </DivWrapper>
    );
  }
}
MainImage.propTypes = {
  image: PropTypes.object,
  className: PropTypes.string
};

export default MainImage;
