import React from 'react';
import Header from '../components/Header';
import PropTypes from 'prop-types';

const HeaderPage = ({ location }) => {
  return <Header pathName={location.pathname} />;
};

HeaderPage.propTypes = {
  location: PropTypes.object
};

export default HeaderPage;
