import { API_REQUEST, FETCH_RECENT_IMAGES } from '../../../constants/requests';

export function fetchRecentImages() {
  return {
    type: API_REQUEST,
    payload: {
      url: '/images/getRecentImages',
      method: 'get',
      nextActionType: FETCH_RECENT_IMAGES
    },
    meta: {
      loaderId: 'fetchRecentImages'
    }
  };
}
