import React from 'react';
import {
  UploadImagePage,
  AdminRecentImagesPage,
  AdminEarlierImagesPage,
  UsersListPage,
  AdminYears2015_2018Page
} from '../pages';
import { Switch } from 'react-router-dom';
import { NavTab } from 'react-router-tabs';
import PrivateRoute from '../components/Common/Routes/private-route';
import PropTypes from 'prop-types';

const AdminMainRoutes = ({ navbarClassName, navbarClassNameActive }) => (
  <div>
    <NavTab
      className={navbarClassName}
      activeClassName={navbarClassNameActive}
      to="/admin/main/uplodaImage"
    >
      Upload Image
    </NavTab>
    {/* <NavTab to="/admin/main/mainImages">Main Images</NavTab> */}
    <NavTab
      className={navbarClassName}
      activeClassName={navbarClassNameActive}
      to="/admin/main/recentImages"
    >
      Recent Images
    </NavTab>
    <NavTab
      className={navbarClassName}
      activeClassName={navbarClassNameActive}
      to="/admin/main/earlierImages"
    >
      Earlier Images
    </NavTab>
    <NavTab
      className={navbarClassName}
      activeClassName={navbarClassNameActive}
      to="/admin/main/2015_2018"
    >
      2015-2018 Images
    </NavTab>
    <NavTab
      className={navbarClassName}
      activeClassName={navbarClassNameActive}
      to="/admin/main/users"
    >
      Show Users
    </NavTab>

    <Switch>
      <PrivateRoute exact path="/admin/main/uplodaImage" component={UploadImagePage} />
      <PrivateRoute exact path="/admin/main/recentImages" component={AdminRecentImagesPage} />
      <PrivateRoute exact path="/admin/main/earlierImages" component={AdminEarlierImagesPage} />
      <PrivateRoute exact path="/admin/main/2015_2018" component={AdminYears2015_2018Page} />
      <PrivateRoute exact path="/admin/main/users" component={UsersListPage} />
    </Switch>
  </div>
);

AdminMainRoutes.propTypes = {
  navbarClassName: PropTypes.string,
  navbarClassNameActive: PropTypes.string
};
export default AdminMainRoutes;
