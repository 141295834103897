import { FETCH_MAIN_IMAGES } from '../../../constants/requests';

const initialState = {
  images: []
};
export const MainReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MAIN_IMAGES.success: {
      return {
        ...state,
        images: action.payload.data.data
      };
    }
    default:
      return state;
  }
};
