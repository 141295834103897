import { SHOW_LOADER, HIDE_LOADER, HIDE_ALL_LOADERS } from '../actions';

const initialState = [];

export const LoaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_LOADER:
      return [...state, action.payload.id];

    case HIDE_LOADER:
      return state.filter(item => item !== action.payload.id);

    case HIDE_ALL_LOADERS:
      return initialState;

    default:
      return state;
  }
};
