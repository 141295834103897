import React from 'react';
import PropTypes from 'prop-types';
import {
  wrapperVhHeights,
  footerVhHeights,
  headerVHeights,
  imagesContainerVHeights,
  screenWidth as constScreenWidth
} from '../../../constants/responsive';
import withScreenDimensions from '../../../hoc/with-screen-dimensions';
import {theme} from '../../../constants/theme';

export const DivWrapper = ({
  className,
  children,
  element,
  minHeight = false,
  maxHeight = false,
  padding,
  screenWidth,
  screenHeight,
  style
}) => {
  const getVhRealHeight = () => {
    if (!element || element === 'undefined') {
      return screenHeight;
    }
    let vhSizes = null;
    //get sizes of every element
    switch (element) {
      case 'wrapper':
        vhSizes = wrapperVhHeights;
        break;
      case 'footer':
        vhSizes = footerVhHeights;
        break;
      case 'header':
        vhSizes = headerVHeights;
        break;
      case 'imagesContainer':
        vhSizes = imagesContainerVHeights;
        break;
      default:
        vhSizes = wrapperVhHeights;
    }
    //calculate real height in px of elements
    let height = screenHeight * (vhSizes.default / 100);
    if (screenHeight < 400) {
      height = screenHeight * (vhSizes.height400 / 100);
    } else if (screenHeight < 500) {
      height = screenHeight * (vhSizes.height500 / 100);
    } else if (screenHeight < 600) {
      height = screenHeight * (vhSizes.height600 / 100);
    } else if (screenHeight < 650) {
      height = screenHeight * (vhSizes.height650 / 100);
    } else if (screenHeight < 700) {
      height = screenHeight * (vhSizes.height700 / 100);
    }
    //deceramnt the padding of element if exist from the given heieght
    let paddingPx = 50;
    if (screenWidth < constScreenWidth.s) {
      paddingPx = 25;
    }
    return element === 'wrapper' && padding ? height - paddingPx : height;
  };

  let styles = !element || element === 'undefined' ? { background: theme.colors.sitePrimary} : {};
  const height = getVhRealHeight();
  if (minHeight) {
    styles = { ...styles, minHeight: height };
  }
  if (maxHeight) {
    styles = { ...styles, maxHeight: height };
  }

  if (!minHeight && !maxHeight) {
    styles = { ...styles, height };
  }
  return (
    <div style={{ ...styles, ...style }} className={className}>
      {children}
    </div>
  );
};

DivWrapper.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.any,
  element: PropTypes.string,
  minHeight: PropTypes.bool,
  maxHeight: PropTypes.bool,
  padding: PropTypes.bool,
  screenWidth: PropTypes.number,
  screenHeight: PropTypes.number
};

export default withScreenDimensions(DivWrapper, true);
