import { combineReducers } from 'redux';
//import authReduer from './authReducer';
import filesReducer from './filesResucer';
import { reducer as formReducer } from 'redux-form';
import { MainReducer } from '../components/Main/reducers';
import { RecentImagesReducer } from '../components/Recent-Images/reducers';
import { EarlierImagesReducer } from '../components/Earlier-Images';
import { LoaderReducer } from '../components/Common/Loader';
import { ContactReducer } from '../components/Contact';
import { AdminReducer } from '../components/Admin';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { Years2015_2018ImagesReducer } from '../components/Years2015-2018-Images';

export default combineReducers({
  //auth: authReduer,
  admin: AdminReducer,
  files: filesReducer,
  form: formReducer,
  main: MainReducer,
  recentImages: RecentImagesReducer,
  earlierImages: EarlierImagesReducer,
  loader: LoaderReducer,
  contact: ContactReducer,
  toastr: toastrReducer,
  years2015_2018: Years2015_2018ImagesReducer
});
