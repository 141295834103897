import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchEarlierImages } from '../actions';
import Wrapper from '../../Common/Wrapper';
import { Loader } from '../../../components/Common/Loader';
// import Gallery from '../../Common/Gallery/gallery';
import GalleryNew from '../../Common/Gallery/galleryNew';

class EarlierImagesManager extends Component {
  componentDidMount() {
    this.props.fetchEarlierImages();
  }

  render() {
    return (
      <Wrapper>
        <Loader id="fetchEarlierImages">
          <GalleryNew category="Earlier" images={this.props.images} />
        </Loader>
      </Wrapper>
    );
  }
}

EarlierImagesManager.propTypes = {
  fetchEarlierImages: PropTypes.func,
  images: PropTypes.array
};

const mapStateToProps = ({ earlierImages }) => ({
  images: earlierImages.images
});

export default connect(
  mapStateToProps,
  { fetchEarlierImages }
)(EarlierImagesManager);
