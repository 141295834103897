import React from 'react';
// import { Row } from 'react-materialize';
// import classNames from 'classnames';
import DivWrapper from '../Common/Div-Wrapper';
import styles from './not-found.module.css';

export const NotFound = () => (
  <DivWrapper element="wrapper">
    <div className={styles.container}>
      <div className={styles.content}>Page not exists.</div>
    </div>
  </DivWrapper>
);

export default NotFound;
