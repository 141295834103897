import { apiRequestActions } from '../utils/api-request-action';

export const API_REQUEST = 'API_REQUEST';
export const API_URL = 'api';

/* Users */
export const FETCH_USER = apiRequestActions('FETCH_USER');

/* Files */
export const FILES_UPDATE = 'FILES_UPDATE';
export const FILES_UPLOAD = apiRequestActions('FILES_UPLOAD');

/* Images */
export const FETCH_MAIN_IMAGES = apiRequestActions('FETCH_MAIN_IMAGES');
export const FETCH_RECENT_IMAGES = apiRequestActions('FETCH_RECENT_IMAGES');
export const FETCH_EARLIER_IMAGES = apiRequestActions('FETCH_EARLIER_IMAGES');
export const FETCH_YEARS_2015_2018_IMAGES = apiRequestActions('FETCH_YEARS_2015_2018_IMAGES');

/* Contact US - Email */
export const SEND_CONTACT_US_MAIL = apiRequestActions('SEND_CONTACT_US_MAIL');
export const RESET_CONTACT_US = 'RESET_CONTACT_US';

/* Admin */
export const AUTH_GOOGLE = apiRequestActions('AUTH_GOOGLE');
export const ADMIN_UPDATE_RECENT_IMAGES_ORDER = apiRequestActions(
  'ADMIN_UPDATE_RECENT_IMAGES_ORDER'
);
export const ADMIN_SORT_RECENT_IMAGES = 'ADMIN_SORT_RECENT_IMAGES';
export const ADMIN_FETCH_RECENT_IMAGES = apiRequestActions('ADMIN_FETCH_RECENT_IMAGES');
export const ADMIN_UPDATE_IMAGE = apiRequestActions('ADMIN_UPDATE_IMAGE');
export const ADMIN_CHANGE_IMG_EDIT_WINDOW_STATUS = 'ADMIN_CHANGE_IMG_EDIT_WINDOW_STATUS';
export const ADMIN_DELETE_IMAGE = apiRequestActions('ADMIN_DELETE_IMAGE');
export const ADMIN_FETCH_EARLIER_IMAGES = apiRequestActions('ADMIN_FETCH_EARLIER_IMAGES');
export const ADMIN_UPDATE_EARLIER_IMAGES_ORDER = apiRequestActions(
  'ADMIN_UPDATE_EARLIER_IMAGES_ORDER'
);
export const ADMIN_SORT_EARLIER_IMAGES = apiRequestActions('ADMIN_SORT_EARLIER_IMAGES');
export const ADMIN_FETCH_YEARS_2015_2018_IMAGES = apiRequestActions('ADMIN_FETCH_YEARS_2015_2018_IMAGES');
export const ADMIN_UPDATE_YEARS_2015_2018_IMAGES_ORDER = apiRequestActions('ADMIN_UPDATE_YEARS_2015_2018_IMAGES_ORDER');
export const ADMIN_SORT_YEARS_2015_2018_IMAGES = apiRequestActions('ADMIN_SORT_YEARS_2015_2018_IMAGES');

export const ADMIN_GET_USERS = apiRequestActions('ADMIN_GET_USERS');
export const ADMIN_ADD_ADMIN = apiRequestActions('ADMIN_ADD_ADMIN');

/*Gallery*/
export const GALLERY_CHANGE_IMAGE_STATUS = 'GALLERY_CHANGE_IMAGE_STATUS';

/*Mix Panel*/
export const APP_TRACK_MIX_PANEL = 'APP_TRACK_MIX_PANEL';
