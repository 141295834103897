export const spaceBetweenImages = {
  xs: 10,
  s: 20,
  m: 40,
  l: 60,
  xl: 80,
  xxl: 80
};

export const screenWidth = {
  xs: 420,
  s: 600,
  m: 768,
  l: 992,
  xl: 1200
};

export const boxMarginSide = {
  xs: 20,
  s: 50,
  m: 50,
  l: 100,
  xl: 120,
  xxl: 140
};

export const numOfImagesPerRows = {
  xs: 2,
  s: 2,
  m: 3,
  l: 4,
  xl: 5
};

export const numOfImagesCols = {
  xs: 2,
  s: 2,
  m: 3,
  l: 3,
  xl: 3,
  xxl: 3
};

export const imageMaxWidth = {
  xs: 90,
  s: 80,
  m: 80,
  l: 80,
  xl: 80,
  xxl: 75
};

export const sortOrder = {
  xs: 'orderSmall',
  s: 'orderSmall',
  m: 'orderLarge',
  l: 'orderLarge',
  xl: 'orderLarge',
  xxl: 'orderLarge'
};

export const wrapperVhHeights = {
  height400: 74,
  height500: 76,
  height600: 78,
  height650: 80,
  height700: 82,
  default: 84
};

export const footerVhHeights = {
  height400: 13,
  height500: 12,
  height600: 11,
  height650: 10,
  height700: 9,
  default: 8
};

export const headerVHeights = {
  height400: 13,
  height500: 12,
  height600: 11,
  height650: 10,
  height700: 9,
  default: 8
};

export const imagesContainerVHeights = {
  height400: 50,
  height500: 55,
  height600: 60,
  height650: 60,
  height700: 60,
  default: 65
};
