import { takeEvery, call } from 'redux-saga/effects';
import {
  SEND_CONTACT_US_MAIL,
  FILES_UPLOAD,
  ADMIN_UPDATE_IMAGE,
  ADMIN_UPDATE_RECENT_IMAGES_ORDER,
  ADMIN_UPDATE_EARLIER_IMAGES_ORDER,
  ADMIN_DELETE_IMAGE,
  ADMIN_ADD_ADMIN
} from '../constants/requests';
import { toastr } from 'react-redux-toastr';

function* showSuccessToast({ meta: { toast } }) {
  if (toast) {
    // const toastrOptions = {
    //   className: toast.className
    // };
    yield call(() => {
      toastr.success(toast.title || 'Action success', toast.messageSuccess);
    });
  }
}

function* showErrorToast({ meta: { toast } }) {
  if (toast) {
    yield call(() => {
      toastr.error('Action failed', toast.messageFailed);
    });
  }
}

export default function* toastSaga() {
  yield takeEvery(
    [
      SEND_CONTACT_US_MAIL.success,
      FILES_UPLOAD.success,
      ADMIN_UPDATE_IMAGE.success,
      ADMIN_UPDATE_RECENT_IMAGES_ORDER.success,
      ADMIN_DELETE_IMAGE.success,
      ADMIN_UPDATE_EARLIER_IMAGES_ORDER.success,
      ADMIN_ADD_ADMIN.success
    ],
    showSuccessToast
  );
  yield takeEvery(
    [
      SEND_CONTACT_US_MAIL.error,
      FILES_UPLOAD.error,
      ADMIN_UPDATE_IMAGE.error,
      ADMIN_UPDATE_RECENT_IMAGES_ORDER.error,
      ADMIN_DELETE_IMAGE.error,
      ADMIN_UPDATE_EARLIER_IMAGES_ORDER.error,
      ADMIN_ADD_ADMIN.error
    ],
    showErrorToast
  );
}
