import { put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { API_URL } from '../constants/requests';
import { createAction } from 'redux-actions';
import { API_BASE_URL } from '../constants/config';

const defaultNextType = {
  pending: 'REQUEST_PENDING',
  success: 'REQUEST_SUCCESS',
  error: 'REQUEST_ERROR'
};

function* fetchData(action) {
  const requestUrl = `${API_URL}${action.payload.url}`;
  const nextType = action.payload.nextActionType || defaultNextType;

  try {
    // const headers = authService.getHeaders(authorizationToken);
    yield put(createAction(nextType.pending, () => action, () => action.meta)());

    let reqObj = {
      method: action.payload.method,
      url: '/' + requestUrl,
      baseURL: API_BASE_URL,
    };
    if (action.payload.data) {
      reqObj.data = action.payload.data;
    }
    const fetchResult = yield axios(reqObj);
    yield put(createAction(nextType.success, () => fetchResult, () => action.meta)(fetchResult));
  } catch (e) {
    yield put(createAction(nextType.error, () => e, () => action.meta)(e));
  }
}

function* apiSaga() {
  yield takeEvery('API_REQUEST', fetchData);
}

export default apiSaga;
