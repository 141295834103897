import React from 'react';
import { Row, Col } from 'react-materialize';
import styles from '../contact.module.css';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export const FieldRow = props => (
  <Row className={classNames(styles.rowStyle, props.additionalRowClass)}>
    <Col s={1} m={3} l={4} xl={4} />
    <Col
      className={classNames(styles.colStyle, props.additionalColClass)}
      s={10}
      m={6}
      l={4}
      xl={4}
    >
      {props.children}
    </Col>
    <Col s={1} m={3} l={4} xl={4} />
  </Row>
);

FieldRow.propTypes = {
  additionalRowClass: PropTypes.string,
  additionalColClass: PropTypes.string,
  children: PropTypes.any
};

export default FieldRow;
