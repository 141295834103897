import React from 'react';
import { Input, Icon } from 'react-materialize';
import PropTypes from 'prop-types';
import styles from './input-field.module.css';
import classNames from 'classnames';

const InputField = props => {
  const {
    type,
    input,
    label = null,
    iconName = null,
    iconClass = '',
    inputClass = '',
    labelClass = '',
    fieldWwrapName = '',
    meta: { touched, error, warning, initial },
    selectOptions,
    placeholder = null
  } = props;
  return (
    <div className={fieldWwrapName}>
      {iconName != null && <Icon className={iconClass}>{iconName}</Icon>}
      {label != null && <div className={labelClass}>{label}</div>}
      {(type === 'text' || type === 'textarea' || type === 'email' || type === 'number') && (
        <div>
          <Input
            l={12}
            xl={12}
            s={12}
            m={12}
            xs={12}
            className={classNames(inputClass, styles.inputPrimaryClass)}
            onChange={input.onChange}
            value={initial || input.value.toString() || ''}
            type={type}
            placeholder={placeholder != null ? placeholder : ''}
          />
        </div>
      )}
      {type === 'select' && (
        <Input type="select" onChange={input.onChange}>
          {selectOptions.map(opt => (
            <option key={opt} value={opt}>
              {opt}
            </option>
          ))}
        </Input>
      )}
      {touched &&
        ((error && <div className={styles.error}>{error}</div>) ||
          (warning && <span>{warning}</span>))}
    </div>
  );
};

InputField.propTypes = {
  type: PropTypes.string,
  input: PropTypes.any,
  label: PropTypes.string,
  iconName: PropTypes.string,
  iconClass: PropTypes.string,
  inputClass: PropTypes.string,
  labelClass: PropTypes.string,
  fieldWwrapName: PropTypes.string,
  meta: PropTypes.object,
  selectOptions: PropTypes.array,
  placeholder: PropTypes.string
};

export default InputField;
