import React from 'react';
import { Row, Col } from 'react-materialize';
import PropTypes from 'prop-types';
import styles from './cv.module.css';

export const SubTitle = ({ children }) => (
  <Row>
    <Col className={styles.subTitleStyle}>{children}</Col>
  </Row>
);

SubTitle.propTypes = {
  children: PropTypes.node
};

export default SubTitle;
