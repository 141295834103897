import React, { Component } from 'react';
import { MainRoutes } from '../routes';
import Toast from './Common/Toast';
// import Div100vh from 'react-div-100vh';
import DivWrapper from './Common/Div-Wrapper';

class App extends Component {
  render() {
    return (
      <DivWrapper minHeight>
        <Toast />
        <MainRoutes>{/*
          <Header />
          <Footer /> */}</MainRoutes>
      </DivWrapper>
    );
  }
}

export default App;
