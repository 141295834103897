import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button } from 'react-materialize';
import { required, email } from '../../../utils/validate';
import { connect } from 'react-redux';
import InputField from '../../Common/Input/input-field';
import styles from '../contact.module.css';
import PropTypes from 'prop-types';
import Wrapper from '../../Common/Wrapper';
import { sendEmail as sendEmailAction, resetContactUs as resetContactUsAction } from '../actions';
import FieldRow from './field-row';
import { Loader } from '../../../components/Common/Loader';

class Contact extends Component {
  componentDidMount() {
    this.props.reset();
    this.onSubmit = this.onSubmit.bind(this);
  }
  componentWillUnmount() {
    this.props.resetContactUs();
  }

  onSubmit(values) {
    this.props.sendEmail({
      name: values.name,
      email: values.email,
      message: values.message
    });
  }

  componentDidUpdate() {
    if (this.props.resetFormValues) {
      this.props.reset();
      this.props.resetContactUs();
    }
  }

  render() {
    return (
      <Wrapper additionalClassNames={['contactUsWrapper', styles.contactUsWrapper]}>
        <Loader id="sendContactUsMail">
          <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
            <FieldRow>
              <Field
                name="name"
                type="text"
                component={InputField}
                label="Name"
                // iconName="account_circle"
                inputClass={styles.textStyle}
                labelClass={styles.labelStyle}
                iconClass={styles.iconStyle}
                validate={required}
              />
            </FieldRow>

            <FieldRow>
              <Field
                name="email"
                type="email"
                component={InputField}
                label="Email"
                inputClass={styles.textStyle}
                labelClass={styles.labelStyle}
                iconClass={styles.iconStyle}
                validate={[required, email]}
              />
            </FieldRow>

            <FieldRow>
              <Field
                name="message"
                type="textarea"
                component={InputField}
                label="Message"
                labelClass={styles.labelStyle}
                inputClass={styles.textStyle}
                validate={required}
              />
            </FieldRow>

            <FieldRow
              additionalRowClass={styles.buttonRowStyle}
              additionalColClass={styles.buttonColStyle}
            >
              <Button className={styles.buttonStyle} type="submit">
                Send
              </Button>
            </FieldRow>
          </form>
        </Loader>
      </Wrapper>
    );
  }
}

Contact.propTypes = {
  sendEmail: PropTypes.func,
  resetContactUs: PropTypes.func,
  reset: PropTypes.func,
  handleSubmit: PropTypes.func,
  resetFormValues: PropTypes.bool
};

const mapStateToProps = ({ contact }) => ({
  resetFormValues: contact.resetFormValues
});

export default connect(
  mapStateToProps,
  { sendEmail: sendEmailAction, resetContactUs: resetContactUsAction }
)(
  reduxForm({
    form: 'contact'
    //onSubmit,
  })(Contact)
);
