import { SEND_CONTACT_US_MAIL, RESET_CONTACT_US } from '../../../constants/requests';

const initialState = {
  messageSent: false,
  messageSentError: false,
  resetFormValues: false
};
export const ContactReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEND_CONTACT_US_MAIL.success: {
      return {
        ...state,
        messageSent: true,
        resetFormValues: true
      };
    }
    case SEND_CONTACT_US_MAIL.error: {
      return {
        ...state,
        messageSent: false,
        messageSentError: true,
        resetFormValues: false
      };
    }

    case RESET_CONTACT_US: {
      return initialState;
    }
    default:
      return state;
  }
};

export default ContactReducer;
