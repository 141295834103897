import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './loader.module.css';

export const Loader = ({ children, id, loader }) => {
  const loading = loader.filter(item => item === id).length > 0;
  return (
    <div className={styles.container}>
      <div className={loading ? styles.overlay : ''}>
        <div className={loading ? styles.loader : ''} />
      </div>
      {children}
    </div>
  );
};

Loader.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  loader: PropTypes.array
};

const mapStateToProps = ({ loader }) => ({ loader });

export default connect(mapStateToProps)(Loader);
