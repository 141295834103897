import { FETCH_EARLIER_IMAGES, GALLERY_CHANGE_IMAGE_STATUS } from '../../../constants/requests';

const initialState = {
  images: []
};
export const EarlierImagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EARLIER_IMAGES.success: {
      return {
        ...state,
        images: action.payload.data.images.map(item => ({ ...item, load: false }))
      };
    }
    case FETCH_EARLIER_IMAGES.error: {
      return state;
    }
    case GALLERY_CHANGE_IMAGE_STATUS: {
      return {
        ...state,
        images: state.images.map(
          item => (item.public_id === action.payload.id ? { ...item, load: true } : item)
        )
      };
    }

    default:
      return state;
  }
};

export default EarlierImagesReducer;
