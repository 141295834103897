import React from 'react';
import { Row, Col } from 'react-materialize';
import PropTypes from 'prop-types';
import styles from './cv.module.css';

export const Title = ({ children }) => (
  <Row>
    <Col className={styles.titleStyle}>{children}</Col>
  </Row>
);

Title.propTypes = {
  children: PropTypes.node
};

export default Title;
