import React from 'react';
import styles from './cv.module.css';
import Wrapper from '../Common/Wrapper';
import Title from './title';
import SubTitle from './sub-title';
import Content from './content';

export const Cv = () => (
  <Wrapper additionalClassNames={['cvWrapperMain', styles.cvWrapper]}>
    <Title>Biography</Title>
    <Content className={styles.bigraphyContent}>
      Tomer Amir (b.1985) is an Israeli Painter who lives and works in Jerusalem.<br/> Amir is a graduate
      of the full-time Jeruslaem Studio school master class program under the guidance<br/> of Israel Hirshberg. <br/>In addition, Amir
      teaches painting privately as well as in various institutions.
    </Content>

    <Title>Exhibitions</Title>
    <SubTitle>Solo:</SubTitle>
    <Content className={styles.yearDesc}>
      2018: “The Dead and the Living” Jaffa Creative, Tel Aviv-Jaffa.
    </Content>
    <Content className={styles.yearDesc}>
      2014: “Still Life ", Aldante, Gallery-Restaurant, Jerusalem.
    </Content>
    <SubTitle>Group:</SubTitle>
    <Content className={styles.yearDesc}>2014: “Be My Guest” The Second Wing, Haifa.</Content>
    <Content className={styles.yearDesc}>
      2013: “5th Annual Invitation Show” Art Museum, University of New Hampshire.
    </Content>
    <Content className={styles.yearDesc}>
      2012: “JSS in the Light of Italy ", Livia Gallery, Jerusalem.
    </Content>
    <Content className={styles.yearDescLast}>
      2010: “Transcription” Copies in the footsteps of the great painters, the Jerusalem Theater.
    </Content>
    {/* <Title>Teaching</Title>

    <Content className={styles.yearDescLast}>
      2015-2018: Painting teacher at the Yad LaBanim Association for Bereaved
      Families
    </Content> */}
  </Wrapper>
);

export default Cv;
