import { API_REQUEST, SEND_CONTACT_US_MAIL, RESET_CONTACT_US } from '../../../constants/requests';

export function sendEmail(data) {
  return {
    type: API_REQUEST,
    payload: {
      url: '/email/send',
      method: 'post',
      data,
      nextActionType: SEND_CONTACT_US_MAIL
    },
    meta: {
      loaderId: 'sendContactUsMail',
      toast: {
        title: 'Message sent successfully',
        messageSuccess: 'Thank you',
        messageFailed: 'Message sent failed'
      }
    }
  };
}

export function resetContactUs() {
  return {
    type: RESET_CONTACT_US
  };
}
